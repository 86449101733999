:root {
  --brand-1: #0052d9;
  --brand-2: #618dff;
  --brand-3: #bad6ff;
  --btn-primary-color: #fff;
}
.ChatApp,
.MessageContainer,
.Navbar,
.Message .Bubble,
.QuickReplies,
.ChatFooter {
  background-repeat: no-repeat;
  background-size: cover;
}
.ChatApp {
  background-color: rgba(0, 0, 0, 0.04);
}
.Message.right .Bubble {
  background-color: #366ef4;
  color: #fff;
}
.QuickReply.highlight {
  border-color: #4a90e2;
}
/* 步骤条覆盖样式 */
.Step-title {
  display: flex;
  align-items: center;
  color: var(--gray-1);
  font-size: 12px;

  small {
    margin-left: 12px;
    color: var(--gray-3);
    font-size: var(--font-size-xs);
  }
}
.Step-desc {
  margin-top: 3px;
  color: var(--gray-3);
  font-size: 10px;
}

/* CardText */
.CardText {
  padding: 12px;
  font-size: 10px;

  .CardTitle + & {
    padding-top: 0;
  }
  p {
    margin: 0;
  }
}

/* CardTitle */
.CardTitle {
  padding: 8pxg;
  font-size: 10px;

  &--center {
    padding: 4px 2px; 
    text-align: center;
  }
  &-title {
    margin: 0;
    font-size: 10px;
    font-weight: 500;
  }
  &-subtitle {
    margin: 0;
    font-size: 10px;
  }
}

