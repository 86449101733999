body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: var(--safe-bottom);  
}

.msg_text {
  font-Size: 12px;
}

.law_text {
  position: relative;
  font-style: italic;
  font-weight: normal;
  color: #6a6a6a;
  font-Size: 10px;
  /* padding: 8px;  */
  padding-left: 10px; /* 为竖线腾出空间 */
}

.law_text::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 2px;
  background-color: gray;
}

/* ant-design list item全局样式 */
.adm-list-item-content-main {
  font-size: 12px;
}

/* 用于去除折叠面板的边框，但可能有副作用 */
.adm-list-body{
  --border-top: 0px ;
  --border-bottom: 0px 
}

.safe-hight {
  padding-bottom: var(--safe-bottom);
}

.SafetyArea{
  background-color: rgb(245, 245, 245);
}